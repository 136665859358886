import React, { useEffect, useState } from "react";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import Body from "../../components/Body.tsx";
import "react-datepicker/dist/react-datepicker.css";
import { getPhotoData } from "../../apis/client/clientApi.ts";
import CustomDatePicker from "../../components/CustomDatePicker.tsx";

interface Photo {
  boraPhotoId: number;
  uploadDate: string;
  fileName: string;
  downloadUrl: string;
}

const Photos: React.FC = () => {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [currentDate, setCurrentDate] = useState(() => {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}`;
  });
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPhotos, setSelectedPhotos] = useState<number[]>([]);
  const [modalPhoto, setModalPhoto] = useState<string | null>(null);

  const fetchPhotoData = async (date: string, pageNumber: number) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (!accessToken || !refreshToken) {
      alert("인증 정보가 없습니다. 다시 로그인해 주세요.");
      return;
    }

    try {
      const response = await getPhotoData(accessToken, refreshToken, date, {
        page: pageNumber,
        size: 16,
        sort: "boraPhotoId,desc"
      });

      if (response.result === true && response.data?.page?.content) {
        setPhotos(response.data.page.content);
        setTotalPages(response.data.page.totalPages);
      } else {
        setPhotos([]);
      }
    } catch (error) {
      console.error("사진 데이터 API 호출 중 오류 발생:", error);
      alert("사진 데이터를 가져오는 중 오류가 발생했습니다.");
    }
  };

  // 보라 s3 버킷 주소 (download_count 제한으로 인한 s3 주소 하드코딩용)
  const S3_BASE_URL = "https://borabucket.s3.ap-northeast-2.amazonaws.com/public/";
  
  // S3 주소 반영 함수
  const convertToPhotoS3Url = (photo: Photo) => {
    // 기존 URL에서 파일명(uuid 포함)만 추출
    const uuidFilename = photo.downloadUrl.split("/").pop(); // 마지막 '/' 이후의 문자열 가져오기
    const pcsystemid = photo.fileName.split("-")[0]; // 파일명에서 'pcsystemid' 추출

    return `${S3_BASE_URL}${pcsystemid}/${uuidFilename}`;
  };


  const startPage = Math.max(0, page - 5);
  const endPage = Math.min(totalPages - 1, page + 4);
  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

  const buttonStyle: React.CSSProperties = {
    marginLeft: "10px",
    padding: "8px 12px",
    backgroundColor: "#405189",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };
  
  // 이전 달 날짜 계산
  const handlePrevMonth = () => {
    const [year, month] = currentDate.split("-").map(Number);
    const newDate = new Date(year, month - 2);
    setCurrentDate(`${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, "0")}`);
  };

  // 다음 달 날짜 계산
  const handleNextMonth = () => {
    const [year, month] = currentDate.split("-").map(Number);
    const newDate = new Date(year, month);
    setCurrentDate(`${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, "0")}`);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const toggleSelect = (photoId: number) => {
    setSelectedPhotos((prev) =>
      prev.includes(photoId) ? prev.filter((id) => id !== photoId) : [...prev, photoId]
    );
  };

  // 전체 선택
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedPhotos(photos.map((photo) => photo.boraPhotoId));
    } else {
      setSelectedPhotos([]);
    }
  };

  // 사진 삭제
  const deleteSelectedPhotos = async () => {
    if (selectedPhotos.length === 0) {
      alert("삭제할 사진을 선택해주세요.");
      return;
    }

    const confirmDelete = window.confirm("선택한 사진을 삭제하시겠습니까?");
    if (!confirmDelete) return; // 사용자가 '아니오' 선택 시 종료
  
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
  
    if (!accessToken || !refreshToken) {
      alert("인증 정보가 없습니다. 다시 로그인해 주세요.");
      return;
    }
  
    try {
      const URL = process.env.REACT_APP_API_ROOT;  // 도메인 연결 주소 설정
      const queryString = selectedPhotos.map(id => `photoIds=${id}`).join("&");
  
      const response = await fetch(`${URL}/api/v2/bora/photo/delete?${queryString}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      let result;
      try {
        result = await response.json();
      } catch (error) {
        console.error("서버 응답이 JSON이 아닙니다.", error);
        alert("서버 오류로 인해 삭제할 수 없습니다.");
        return;
      }
  
      if (response.status === 200) {
        alert(result.message);
        setPhotos(prevPhotos =>
          prevPhotos.filter(photo => !selectedPhotos.includes(photo.boraPhotoId))
        );
        setSelectedPhotos([]);
      } else if (response.status === 403) {
        alert("삭제 권한이 없는 사진이 포함되어 있습니다.");
      } else if (response.status === 404) {
        alert("존재하지 않거나 이미 삭제된 데이터입니다.");
      } else {
        alert("삭제 중 오류가 발생했습니다.");
      }
    } catch (error) {
      console.error("사진 삭제 API 호출 중 오류 발생:", error);
      alert("서버 오류로 인해 삭제할 수 없습니다.");
    }
  };

  // 다운로드 버튼 클릭 시 알림 추가
  const handleDownloadClick = () => {
    alert("준비 중입니다.");
  };

  const openModal = (photoUrl: string) => setModalPhoto(photoUrl);
  const closeModal = () => setModalPhoto(null);

  useEffect(() => {
    fetchPhotoData(currentDate, page);
  }, [currentDate, page]);

  return (
    <Body title="사진" breadcrumb={["홈", "사진"]}>
      <div
        className="date-navigation"
        style={{
          display: "flex",
          justifyContent: "space-between", // 양쪽 정렬
          alignItems: "center",
          padding: "10px 20px",
        }}
      >
        {/* 전체 선택 체크박스 */}
        <div className="all-checkbox">
          <input type="checkbox" onChange={handleSelectAll} />
          <label>전체 선택</label>
        </div>

        {/* DatePicker 중앙 정렬 */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            justifyContent: "space-between",
            width: "280px",
            minWidth: "280px",
            marginLeft: "70px"
          }}
        >
          <button className="nav-button" onClick={handlePrevMonth}>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 18l-6-6 6-6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>


          <div style={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
            <CustomDatePicker currentDate={currentDate} setCurrentDate={setCurrentDate} />
          </div>

          <button className="nav-button" onClick={handleNextMonth}>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18l6-6-6-6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
        
        {/* 삭제 & 다운로드 버튼 */}
        <div>
          <button onClick={deleteSelectedPhotos} style={buttonStyle}>삭제</button>
          <button onClick={handleDownloadClick} style={buttonStyle}>다운로드</button>
        </div>
      </div>
      <div className="box-container" style={{ backgroundColor: "white", borderRadius: "10px", padding: "20px" }}>
        <div className="photo-grid" style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "10px"}}>
          {photos.length > 0 ? (
            photos.map((photo) => (
              <div
                  key={photo.boraPhotoId}
                  className="photo-item"
                  style={{
                    position: "relative",
                    textAlign: "center",
                    border: selectedPhotos.includes(photo.boraPhotoId) ? "4px solid green" : "none",
                    borderRadius: "10px",
                    marginTop: "20px",
                  }}
                  onDoubleClick={() => openModal(convertToPhotoS3Url(photo))}
                >
                  <div
                    style={{
                      border: selectedPhotos.includes(photo.boraPhotoId) ? "none" : "2px solid #405189",
                      borderRadius: "10px",
                      padding: "10px",
                      backgroundColor: "white",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ position: "absolute", top: "10px", left: "10px", zIndex: 2 }}
                      checked={selectedPhotos.includes(photo.boraPhotoId)}
                      onChange={() => toggleSelect(photo.boraPhotoId)}
                    />
                    <span
                      style={{
                        fontSize: "12px",
                        color: "gray",
                        marginBottom: "5px",
                      }}
                    >
                      {new Date(photo.uploadDate).toLocaleDateString("ko-KR", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}{" "}
                      {new Date(photo.uploadDate).toLocaleTimeString("ko-KR", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true, // 오전/오후 표시
                      })}
                    </span>

                    <img
                      src={convertToPhotoS3Url(photo)}
                      alt="사진"
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "10px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => toggleSelect(photo.boraPhotoId)}
                    />
                  </div>
                </div>

            ))
          ) : (
            <p style={{ gridColumn: "span 4", textAlign: "center" }}>표시할 사진이 없습니다.</p>
          )}
        </div>
        <div className="pagination" style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              style={{
                margin: "0 5px",
                padding: "5px 10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                backgroundColor: pageNumber === page ? "#405189" : "white",
                color: pageNumber === page ? "white" : "black",
                cursor: "pointer",
              }}
            >
              {pageNumber + 1}
            </button>
          ))}
        </div>
      </div>

      {modalPhoto && (
        <div className="modal" style={modalStyle} onClick={closeModal}>
          <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
            <img src={modalPhoto} alt="" style={{ width: "100%", height: "100%" }} />
            <button onClick={closeModal} style={{ backgroundColor : "#405189", color : "white", padding: "10px", borderRadius: "7px", marginTop: "10px"}}>닫기</button>
          </div>
        </div>
      )}
    </Body>
  );
};

// 스타일 객체 타입 정의
const modalStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};
const modalContentStyle: React.CSSProperties = {
  width: "90%",
  maxWidth: "1300px",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "10px",
  textAlign : "center"
};


export default Photos;
