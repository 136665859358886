import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale/ko";

interface CustomDatePickerProps {
  currentDate: string;
  setCurrentDate: (date: string) => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ currentDate, setCurrentDate }) => {
  return (
    <DatePicker
      locale={ko} // 한국어 적용
      selected={new Date(currentDate)}
      onChange={(date: any) => {
        if (date) {
          const formattedDate = date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, "0");
          setCurrentDate(formattedDate);
        }
      }}
      dateFormat="yyyy-MM"
      showMonthYearPicker
      renderCustomHeader={({ date, changeYear }) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(Number(value))}
            style={{
              padding: "6px 10px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "white",
              cursor: "pointer",
              outline: "none",
              appearance: "none",
              backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='%23405189'><path d='M7 10l5 5 5-5z'></path></svg>")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 10px center",
              backgroundSize: "16px",
              paddingRight: "35px",
              transition: "border 0.3s ease",
            }}
            onMouseOver={(e) => (e.currentTarget.style.border = "1px solid #405189")}
            onMouseOut={(e) => (e.currentTarget.style.border = "1px solid #ccc")}
          >
            {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - 5 + i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      )}
      customInput={
        <button
          style={{
            backgroundColor: "#405189",
            color: "white",
            padding: "10px 15px",
            border: "none",
            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: "bold",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "130px", // 버튼 크기 고정
            justifyContent: "center",
            transition: "background-color 0.3s ease-in-out",
          }}
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#334b74")}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#405189")}
        >
          📅 {currentDate}
        </button>
      }
    />
  );
};

export default CustomDatePicker;
