import React from "react";
import { Routes, Route } from "react-router-dom";
import TimeAndCount from "./pages/data/TimeAndCount.tsx";
import Dashboard from "./pages/dashboard/Dashboard.tsx";
import Login from "./pages/Login.tsx";
import { PrivateRoute } from "./routes/PrivateRoute.tsx";
import Signup from "./pages/Signup.tsx";
import Label from "./pages/data/Label.tsx";
import Management from "./pages/user/management.tsx";
import VisitBook from "./pages/monitoring/VisitBook.tsx";
import AdminMonitoring from "./pages/monitoring/AdminMonitoring.tsx";
import AquaQrcode from "./pages/monitoring/AquaQrcode.tsx";
import Emailmanage from "./pages/user/Emailmanage.tsx";
import DataView from "./pages/client/DataView.tsx";
import FAQ from "./pages/client/FAQ.tsx";
import Photos from "./pages/client/Photos.tsx";
import GuestBook from "./pages/client/GuestBook.tsx";
import Inquiry from "./pages/client/Inquiry.tsx";
import Monitoring from "./pages/client/Monitoring.tsx";
import Reviews from "./pages/client/Reviews.tsx";

const App: React.FC = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Signup" element={<Signup />} />
        <Route element={<PrivateRoute />}>
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/TimeAndCount" element={<TimeAndCount />} />
          <Route path="/Label" element={<Label />} />
          <Route path="/Management" element={<Management />} />
          <Route path="/VisitBook" element={<VisitBook />} />
          <Route path="/AdminMonitoring" element={<AdminMonitoring />} />
          <Route path="/AquaQrcode" element={<AquaQrcode />} />
          <Route path="/Emailmanage" element={<Emailmanage />} />
          <Route path="/DataView" element={<DataView />} />
          <Route path="/Photos" element={<Photos />} />
          <Route path="/GuestBook" element={<GuestBook />} />
          <Route path="/Monitoring" element={<Monitoring />} />
          <Route path="/Reviews" element={<Reviews />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/Inquiry" element={<Inquiry />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
