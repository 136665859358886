import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import Body from "../../components/Body.tsx";
import SearchBar from "../../components/SearchBar.tsx";
import "react-datepicker/dist/react-datepicker.css";

const Inquiry: React.FC = () => {
  const title = "1:1 문의"; // 페이지 제목
  const breadcrumb = ["홈", "1:1 문의"]; // 페이지 경로
  const [inquiries, setInquiries] = useState<any[]>([]); // 1:1 문의 데이터 상태
  const navigate = useNavigate();

  // 검색 이벤트 핸들러
  const handleSearch = (startDate: string, endDate: string, keyword: string) => {
    console.log("검색 요청:", { startDate, endDate, keyword });
    // 키워드 필터링 로직 추가 가능
    if (keyword) {
      const filteredInquiries = inquiries.filter((inquiry) =>
        inquiry.message.includes(keyword) || inquiry.user.includes(keyword)
      );
      setInquiries(filteredInquiries);
    }
  };

  // 페이지 로드 시 데이터 가져오기
  useEffect(() => {
    alert("준비 중입니다.")
    navigate(-1);
  }, []);

  return (
    <Body title={title} breadcrumb={breadcrumb}>
      <SearchBar onSearch={handleSearch} />
    </Body>
  );
};

export default Inquiry;
