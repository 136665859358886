import React, { useEffect, useState } from "react";
import awesomeLogo from "../assets/images/awesomepia/awesomelog.png";
import { useLocation } from "react-router-dom";
import "../styles/common/style.css";
import "../styles/common/common.css";

const SideBar: React.FC = () => {
  const [activeDepth1, setActiveDepth1] = useState<number | null>(null);
  const [activeDepth2, setActiveDepth2] = useState<number | null>(null);
  const [activeDepth3, setActiveDepth3] = useState<number | null>(null);
  const [role, setRole] = useState<string>(""); // 사용자 역할
  const [mainboard, setMainboard] = useState<number>(0);
  const location = useLocation();

  // Depth1 클릭 핸들러
  const handleDepth1Click = (index: number) => {
    setActiveDepth1((prevIndex) => (prevIndex === index ? null : index));
    setMainboard(0);
    localStorage.setItem("main", "0");
    localStorage.setItem("depth", activeDepth1 === index ? "" : String(index));
    if (activeDepth1 === index) {
      setActiveDepth3(0);
      localStorage.setItem("depth3", "0");
    }
  };

  // Depth2 클릭 핸들러
  const handleDepth2Click = (index: number) => {
    setActiveDepth2(index);
    localStorage.setItem("depth2", String(index));
  };

  // Depth3 클릭 핸들러
  const handleDepth3Click = (index: number) => {
    setActiveDepth3((prevIndex) => (prevIndex === index ? null : index));
    localStorage.setItem("depth3", activeDepth3 === index ? "" : String(index));
  };

  // 대시보드 클릭 핸들러
  const handleDashboardClick = (index: number) => {
    localStorage.setItem("depth", String(index));
    localStorage.setItem("depth2", String(index));
    localStorage.setItem("depth3", String(index));
    localStorage.setItem("main", "1");
    localStorage.setItem("depth3", "0");
  };

  // 컴포넌트 마운트 시 로컬 스토리지에서 상태 초기화
  useEffect(() => {
    const role = localStorage.getItem("role");
    setRole(role || ""); // 기본 값은 빈 문자열
    const depth = parseInt(localStorage.getItem("depth") || "0", 10);
    setActiveDepth1(depth);
    const depth2 = parseInt(localStorage.getItem("depth2") || "0", 10);
    setActiveDepth2(depth2);
    const depth3 = parseInt(localStorage.getItem("depth3") || "0", 10);
    setActiveDepth3(depth3);
    const board = parseInt(localStorage.getItem("main") || "0", 10);
    setMainboard(board);
  }, []);

  const getActiveStyle = (path: string) => {
    return location.pathname === path ? { color: "white", fontWeight: "bold" } : {};
  };

  // ADMIN 사이드바
  const renderAdminSidebar = () => (
    <>
      {/* 대시보드 메뉴 */}
      <li className={`${mainboard === 1 ? "active" : ""}`}>
        <a href="/Dashboard" onClick={() => handleDashboardClick(0)}>
          <i className="xi-layout-snb-o"></i>대시보드
        </a>
      </li>

      {/* 데이터 조회 메뉴 */}
      <li className={`${activeDepth1 === 1 ? "toggleActive" : ""}`}>
        <p onClick={() => handleDepth1Click(1)}>
          <i className="xi-users"></i>데이터 조회
        </p>
        <ul
          className="sideGnb--depth2"
          style={{ display: activeDepth1 === 1 ? "block" : "none" }}
        >
          <li className={`${activeDepth2 === 1 ? "toggleActive" : ""}`}>
            <a href="/TimeAndCount" onClick={() => handleDepth2Click(1)}>
              시간 및 횟수 데이터
            </a>
          </li>
          <li className={`${activeDepth2 === 2 ? "toggleActive" : ""}`}>
            <a href="/Label" onClick={() => handleDepth2Click(2)}>
              라벨 관련 데이터
            </a>
          </li>
        </ul>
      </li>

      {/* 관리자용 모니터링 메뉴 */}
      <li className={`${activeDepth1 === 2 ? "toggleActive" : ""}`}>
        <p onClick={() => handleDepth1Click(2)}>
          <i className="xi-credit-card"></i>모니터링
        </p>
        <ul
          className="sideGnb--depth2"
          style={{ display: activeDepth1 === 2 ? "block" : "none" }}
        >
          <li className={`${activeDepth2 === 5 ? "toggleActive" : ""}`}>
            <a href="/AdminMonitoring" onClick={() => handleDepth2Click(5)}>
              관리자용 모니터링
            </a>
          </li>
          <li className={`${activeDepth2 === 9 ? "toggleActive" : ""}`}>
            <a href="/AquaQrcode" onClick={() => handleDepth2Click(9)}>
              아쿠아 QR코드 관리
            </a>
          </li>
        </ul>
      </li>

      {/* 사용자 관리 메뉴 */}
      <li className={`${activeDepth1 === 3 ? "toggleActive" : ""}`}>
        <p onClick={() => handleDepth1Click(3)}>
          <i className="xi-cloud"></i>사용자관리
        </p>
        <ul
          className="sideGnb--depth2"
          style={{ display: activeDepth1 === 3 ? "block" : "none" }}
        >
          <li className={`${activeDepth2 === 7 ? "toggleActive" : ""}`}>
            <a href="/Management" onClick={() => handleDepth2Click(7)}>
              계정관리
            </a>
          </li>
          <li className={`${activeDepth2 === 12 ? "toggleActive" : ""}`}>
            <a href="/Emailmanage" onClick={() => handleDepth2Click(12)}>
              이메일 발송 관리
            </a>
          </li>
        </ul>
      </li>
    </>
  );

  // CLIENT_MANAGER 사이드바
  const renderClientManagerSidebar = () => (
    <>
      {/* 홈화면 메뉴 */}
      <li className={`${mainboard === 1 ? "active" : ""}`}>
        <a href="/Dashboard" onClick={() => handleDashboardClick(0)} style={getActiveStyle("/Dashboard")}>
          홈화면
        </a>
      </li>

      {/* 데이터 조회 메뉴 */}
      <li>
        <a
          href="/DataView"
          onClick={() => {
            setMainboard(2); // 메인보드 상태 업데이트
            localStorage.setItem("main", "2");
          }} style={getActiveStyle("/DataView")}
        >
          데이터 조회
        </a>
      </li>

      {/* 사진 메뉴 */}
      <li>
        <a
          href="/Photos"
          onClick={() => {
            setMainboard(3); // 메인보드 상태 업데이트
            localStorage.setItem("main", "3");
          }} style={getActiveStyle("/Photos")}
        >
          사진
        </a>
      </li>

      {/* 방명록 메뉴 */}
      <li>
        <a
          href="/GuestBook"
          onClick={() => {
            setMainboard(4); // 메인보드 상태 업데이트
            localStorage.setItem("main", "4");
          }} style={getActiveStyle("/GuestBook")}
        >
          방명록
        </a>
      </li>

      {/* 모니터링 메뉴 */}
      <li>
        <a
          href="/Monitoring"
          onClick={() => {
            setMainboard(5); // 메인보드 상태 업데이트
            localStorage.setItem("main", "5");
          }} style={getActiveStyle("/Monitoring")}
        >
          모니터링
        </a>
      </li>

      {/* 사용후기 메뉴 */}
      <li>
        <a
          href="/Reviews"
          onClick={() => {
            setMainboard(6); // 메인보드 상태 업데이트
            localStorage.setItem("main", "6");
          }} style={getActiveStyle("/Reviews")}
        >
          사용후기
        </a>
      </li>

      {/* FAQ 메뉴 */}
      <li>
        <a
          href="/FAQ"
          // onClick={() => {
          //   setMainboard(7); // 메인보드 상태 업데이트
          //   localStorage.setItem("main", "7");
          // }}
          onClick={(e) => {
            e.preventDefault(); // 기본 링크 이동 방지
            alert("준비 중입니다."); // 알림 표시
          }} style={getActiveStyle("/FAQ")}
        >
          FAQ
        </a>
      </li>

      {/* 1:1 문의 메뉴 */}
      <li>
        <a
          href="/Inquiry"
          // onClick={() => {
          //   setMainboard(8); // 메인보드 상태 업데이트
          //   localStorage.setItem("main", "8");
          // }}
          onClick={(e) => {
            e.preventDefault(); // 기본 링크 이동 방지
            alert("준비 중입니다."); // 알림 표시
          }} style={getActiveStyle("/Inquiry")}
        >
          1:1 문의
        </a>
      </li>
    </>
  );


  return (
    <div className="side">
      <div className="logo">
        <a href="/Dashboard" onClick={() => handleDashboardClick(0)}>
          <img src={awesomeLogo} alt="회사로고" />
        </a>
      </div>
      <ul className="sideGnb--depth1">
        {role === "ADMIN" && renderAdminSidebar()}
        {role === "CLIENT_MANAGER" && renderClientManagerSidebar()}
      </ul>
    </div>
  );
};

export default SideBar;
