import React, { useEffect, useState } from "react";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import Body from "../../components/Body.tsx";
import SearchBar from "../../components/SearchBar.tsx";
import "react-datepicker/dist/react-datepicker.css";
import { getAggregateData } from "../../apis/client/clientApi.ts";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

// ChartComponent Props 정의
interface ChartComponentProps {
  title: string; // 차트 제목
  data: any[]; // 차트 데이터
  dataList: string[]; // 데이터 리스트
  colorArray: string[]; // 색상 배열
  useCustomLabel?: boolean; // 커스텀 라벨 사용 여부 (선택적)
}

// 숫자를 세 자리마다 콤마(,)를 추가하여 변환하는 함수
const formatNumberWithCommas = (num: number) => {
  return new Intl.NumberFormat("en-US").format(num);
};

// ChartComponent 컴포넌트 정의
const ChartComponent: React.FC<ChartComponentProps & { isLoading: boolean }> = ({
  title,
  data,
  dataList,
  colorArray,
  useCustomLabel = false,
  isLoading,
}) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      {isLoading ? (
        <div className="chart-loading">
          <div className="spinner"></div> {/* 로딩 애니메이션 */}
        </div>
      ) : (
        <BarChart data={data} margin={{ top: 70, right: 30, left: 20, bottom: 5 }}>
          <text
            x="50%"
            y="5%"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="20"
            fill="#333"
            style={{ pointerEvents: "none", zIndex: 1 }} // title은 이벤트 비활성화
          >
            {title}
          </text>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={formatNumberWithCommas} />
          <Tooltip 
            wrapperStyle={{
              zIndex: 9999, // 가장 위에 표시 (우선 순위 지정)
              position: "absolute",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
            formatter={(value) => formatNumberWithCommas(Number(value))}
          />
          <Legend wrapperStyle={{ marginLeft: "30px" }} />
          {Object.keys(data[0] || {}).filter((key) => key !== "name").map((key, index) => (
            <Bar key={index} dataKey={key} fill={colorArray[index % colorArray.length]}>
              <LabelList dataKey={key} position="top" formatter={(value: any) => formatNumberWithCommas(Number(value))} />
            </Bar>
          ))}
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};


// TimeAndCount 컴포넌트 정의
const DataView: React.FC = () => {
  const title = "데이터 조회";
  const breadcrumb = ["홈", "데이터 조회"];
  const [aggregateData, setAggregateData] = useState<any | null>(null);
  const [summaryMent, setSummaryMent] = useState<React.ReactNode>("데이터가 없습니다."); // 종합 멘트 상태 추가
  const [isLoading, setIsLoading] = useState<boolean>(false); // 로딩 상태 추가
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  useEffect(() => {

    // 초기 화면에 제공되는 데이터 적용
    const fetchInitialData = async () => {
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      const yesterdayString = yesterday.toISOString().split("T")[0];
  
      const oneMonthAgo = new Date(yesterday);
      oneMonthAgo.setMonth(yesterday.getMonth() - 1);
      const oneMonthAgoString = oneMonthAgo.toISOString().split("T")[0];
  
      setStartDate(oneMonthAgoString);
      setEndDate(yesterdayString);
  
      await handleSearchBarChange(oneMonthAgoString, yesterdayString, "");
    };
  
    fetchInitialData();
  }, []);
  

  // 그래프 차트에 쓰이는 색상 리스트 정의
  const colorArray = [
    "#1f78b4",
    "#33a02c",
    "#e31a1c",
    "#ff7f00",
    "#6a3d9a",
    "#a6cee3",
    "#b2df8a",
    "#fb9a99",
  ];

  // 검색바의 변경 이벤트 핸들러
  const handleSearchBarChange = async (startDate: string, endDate: string, selectedOption: string) => {
    setIsLoading(true); // 로딩 시작
    setSummaryMent("데이터를 불러오는 중...");
  
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
  
    try {
      const response = await getAggregateData(accessToken!, refreshToken!, startDate, endDate);
      if (response.result) {
        setAggregateData(response.data);
        setSummaryMent(generateSummaryMent(response.data));
      } else {
        setSummaryMent("조회 기간 내 데이터가 존재하지 않습니다.");
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
      setSummaryMent("데이터를 불러오는 데 실패했습니다.");
    }
    setIsLoading(false); // 항상 로딩 상태를 false로 설정
  };
  
  // 데이터 조회 종합 멘트 정의
  const generateSummaryMent = (data: any) => {
    if (!data) return "데이터가 없습니다.";
  
    const mostUsedMode = data.mode ? getMaxKey(data.mode) : "데이터 없음";
    let mostUsedLanguage = data.language ? getMaxKey(data.language) : "데이터 없음";
    const mostUsedFeature = data.feature ? getMaxKey(data.feature) : "데이터 없음";
    const mostUsedHourRange = data.time_of_day ? getMaxHourRange(data.time_of_day) : "데이터 없음";
    const mostUsedDay = data.day_of_week ? getMaxKey(data.day_of_week) : "데이터 없음";
    const mostUsedMonth = data.month ? getMaxKey(data.month) : "데이터 없음";

    if (mostUsedLanguage === '한글') {
      mostUsedLanguage = '한국어';
    }
  
    return (
      <>
        <strong>가장 많이 사용된 모드</strong>는{" "}
        <span style={{ color: "#007bff", fontWeight: "bold" }}>{mostUsedMode}</span>입니다.{"\n"}
  
        <strong>가장 많이 사용된 언어</strong>는{" "}
        <span style={{ color: "#007bff", fontWeight: "bold" }}>{mostUsedLanguage}</span>입니다.{"\n"}
  
        <strong>가장 많이 사용된 기능</strong>은{" "}
        <span style={{ color: "#007bff", fontWeight: "bold" }}>{mostUsedFeature}</span>입니다.{"\n"}
  
        <strong>가장 많이 이용된 시간대</strong>는{" "}
        <span style={{ color: "#007bff", fontWeight: "bold" }}>{mostUsedHourRange}</span>입니다.{"\n"}
  
        <strong>가장 많이 이용된 요일</strong>은{" "}
        <span style={{ color: "#007bff", fontWeight: "bold" }}>{mostUsedDay}</span>입니다.{"\n"}
  
        <strong>가장 많이 이용된 달</strong>은{" "}
        <span style={{ color: "#007bff", fontWeight: "bold" }}>{mostUsedMonth}월</span>입니다.
      </>
    );
  };

  // 데이터 조회 키값 반환
  const getMaxKey = (data: Record<string, number>) => {
    return Object.entries(data).reduce(
      (max, [key, value]) => (value > max.value ? { key, value } : max),
      { key: "", value: 0 }
    ).key;
  };

  // 데이터 조회 시간대 정렬 및 반환
  const getMaxHourRange = (hourData: Record<string, number> | undefined) => {
    if (!hourData || Object.keys(hourData).length === 0) return "데이터 없음";

    const sortedHours = Object.keys(hourData).sort((a, b) => hourData[b] - hourData[a]);
    return `${sortedHours[0]}시`;
  };

  // 차트 데이터에 대한 필터링 
  const convertToChartData = (
    data: Record<string, number> | undefined,
    dataKeyName: string,
    filterCallback?: (key: string) => boolean, // 필터링 규칙을 함수로 전달
    sortCallback?: (a: string, b: string) => number // 정렬 규칙을 함수로 전달
  ) => {
    if (!data || Object.keys(data).length === 0) return [];
  
    let processedData = Object.entries(data);
    
    // 모드별 누적 진입 횟수 정렬
    if (dataKeyName === "modeValue") {
      const modeOrder: Record<string, number> = {
        "대기 모드": 1,
        "실시간 모드": 2,
        "XR 모드": 3,
        "맑은 날 모드": 4,
        "방명록": 5
      };
      
      processedData = processedData.sort(([a], [b]) => (modeOrder[a] || 999) - (modeOrder[b] || 999));
    }

    // 언어별 누적 사용 횟수 정렬
    if (dataKeyName === "languageValue") {
      const languageOrder: Record<string, number> = {
        "한글": 1,
        "영어": 2,
        "중국어": 3,
        "일어" :4
      };

      processedData = processedData.sort(([a], [b]) => (languageOrder[a] || 999) - (languageOrder[b] || 999));
    }

    // 월별 누적 사용 횟수 (숫자 뒤에 '월' 적용)
    if (dataKeyName === "monthValue") {
      processedData = processedData.map(([key, value]) => [`${key}월`, value]);
    }

    // 언어별 누적 사용 횟수 (한글 -> 한국어)
    if (dataKeyName === "languageValue") {
      processedData = processedData.map(([key, value]) =>
        key === "한글" ? ["한국어", value] : [key, value]
      );
    }

    if (filterCallback) {
      // 전달된 필터링 함수(filterCallback) 적용
      processedData = processedData.filter(([key]) => filterCallback(key));
    }
  
    if (sortCallback) {
      // 전달된 정렬 함수(sortCallback) 적용
      processedData = processedData.sort(([a], [b]) => sortCallback(a, b));
    }
  
    return [
      {
        ...Object.fromEntries(processedData), // 기존 방식 유지 (data를 그대로 유지)
      },
    ];
  };

  // 시간대 09~18까지의 데이터 추출
  const timeOfDayFilter = (key: string) => {
    const startHour = parseInt(key.split("-")[0]); // "09-10" → 09 추출
    return startHour >= 9 && startHour <= 17; // 09~17시 데이터만 남김
  };
  
  // 시간대 정렬
  const timeOfDaySort = (a: string, b: string) => {
    const startA = parseInt(a.split("-")[0]);
    const startB = parseInt(b.split("-")[0]);
    return startA - startB; // 낮은 시간대부터 정렬
  };

  // 요일 인덱싱
  const dayOfWeekOrder: Record<string, number> = {
    "일요일": 0,
    "월요일": 1,
    "화요일": 2,
    "수요일": 3,
    "목요일": 4,
    "금요일": 5,
    "토요일": 6
  };
  
  // 요일 정렬
  const dayOfWeekSort = (a: string, b: string) => {
    return dayOfWeekOrder[a] - dayOfWeekOrder[b]; // 미리 정의된 순서에 따라 정렬
  };

  // 언어 중 Unknown 키 제거
  const languageFilter = (key: string) => key !== "Unknown"; 
  
  return (
    <>
      <Body title={title} breadcrumb={breadcrumb}>
        <SearchBar onSearch={handleSearchBarChange} calendar />
          <div className="summary-ment" style={{ padding: "20px", borderRadius: "10px" }}>
            <p style={{ fontSize: "18px", lineHeight: "1.8", whiteSpace: "pre-line" }}>
              {isLoading ? "⏳ 데이터를 불러오는 중입니다..." : summaryMent}
            </p>
          </div>
          <div className="dataviewbox-container">
            <div className="box-row">
                <ChartComponent
                  title="모드별 누적 진입횟수"
                  data={convertToChartData(aggregateData?.mode, "modeValue")}
                  dataList={["modeValue"]}
                  colorArray={colorArray}
                  isLoading={isLoading}
                />
                <ChartComponent
                  title="언어별 누적 사용횟수"
                  data={convertToChartData(aggregateData?.language, "languageValue", languageFilter)}
                  dataList={["languageValue"]}
                  colorArray={colorArray}
                  isLoading={isLoading}
                />
            </div>
            <div className="box-row">
                <ChartComponent
                  title="기능별 누적 사용횟수"
                  data={convertToChartData(aggregateData?.feature, "featureValue")}
                  dataList={["featureValue"]}
                  colorArray={colorArray}
                  isLoading={isLoading}
                />
                <ChartComponent
                  title="시간대 누적 사용횟수"
                  data={convertToChartData(aggregateData?.time_of_day, "timeValue", timeOfDayFilter, timeOfDaySort)}
                  dataList={["timeValue"]}
                  colorArray={colorArray}
                  isLoading={isLoading}
                />
            </div>
            <div className="box-row">
                <ChartComponent
                  title="요일별 누적 사용횟수"
                  data={convertToChartData(aggregateData?.day_of_week, "dayValue", undefined, dayOfWeekSort)}
                  dataList={["dayValue"]}
                  colorArray={colorArray}
                  isLoading={isLoading}
                />
                <ChartComponent
                  title="월별 누적 사용횟수"
                  data={convertToChartData(aggregateData?.month, "monthValue")}
                  dataList={["monthValue"]}
                  colorArray={colorArray}
                  isLoading={isLoading}
                />
            </div>
          </div>
      </Body>
    </>
  );
};

export default DataView;
