import React, { useEffect, useState } from "react";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import Body from "../../components/Body.tsx";
import CustomDatePicker from "../../components/CustomDatePicker.tsx";
import { getReviewListData, getReviewDetailData } from "../../apis/client/clientApi.ts";

interface Review {
  reviewId: number;
  memo: string;
  link?: string;
  insertTs: string;
  images?: string[];
}

const Reviews: React.FC = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [currentDate, setCurrentDate] = useState(() => {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}`;
  });
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [modalReview, setModalReview] = useState<Review | null>(null);

  const fetchReviewData = async (date: string, pageNumber: number) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (!accessToken || !refreshToken) {
        alert("인증 정보가 없습니다. 다시 로그인해 주세요.");
        return;
    }

    try {
        // 사용 후기 리스트 조회
        const response = await getReviewListData(accessToken, refreshToken, date, {
            page: pageNumber,
            size: 16,
            sort: "reviewId,desc",
        });

        if (response.result === true && response.data?.content) {
            const reviewList = response.data.content;

            // 각 리뷰의 ID로 상세 정보 조회 (병렬 호출)
            const detailedReviews = await Promise.all(
                reviewList.map(async (review: any) => {
                    try {
                        const detailResponse = await getReviewDetailData(accessToken, refreshToken, review.id.toString());

                        // 이미지가 존재하면 추가
                        return {
                            ...review,
                            images: detailResponse.result && detailResponse.data?.images ? detailResponse.data.images : [],
                        };
                    } catch (error) {
                        console.error(`리뷰 상세 조회 실패 (ID: ${review.id})`, error);
                        return { ...review, images: [] }; // 실패 시 빈 배열 유지
                    }
                })
            );

            // 리뷰 목록 업데이트
            setReviews(detailedReviews);
            setTotalPages(response.data.totalPages);
        } else {
            setReviews([]);
        }
    } catch (error) {
        console.error("리뷰 데이터 API 호출 중 오류 발생:", error);
        alert("리뷰 데이터를 가져오는 중 오류가 발생했습니다.");
    }
  };


  useEffect(() => {
    fetchReviewData(currentDate, page);
  }, [currentDate, page]);

  const handlePrevMonth = () => {
    const [year, month] = currentDate.split("-").map(Number);
    const newDate = new Date(year, month - 2);
    setCurrentDate(`${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, "0")}`);
  };

  const handleNextMonth = () => {
    const [year, month] = currentDate.split("-").map(Number);
    const newDate = new Date(year, month);
    setCurrentDate(`${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, "0")}`);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const openModal = (review: Review) => setModalReview(review);
  const closeModal = () => setModalReview(null);

  const startPage = Math.max(0, page - 5);
  const endPage = Math.min(totalPages - 1, page + 4);
  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

  return (
    <Body title="사용 후기" breadcrumb={["홈", "사용 후기"]}>
      <div className="date-navigation" style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px 20px" }}>
        {/* DatePicker 중앙 정렬 */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            width: "280px",
            minWidth: "280px"
          }}
        >
          <button className="nav-button" onClick={handlePrevMonth}>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 18l-6-6 6-6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>


          <div style={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
            <CustomDatePicker currentDate={currentDate} setCurrentDate={setCurrentDate} />
          </div>

          <button className="nav-button" onClick={handleNextMonth}>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18l6-6-6-6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      </div>

      <div className="box-container" style={{ backgroundColor: "white", borderRadius: "10px", padding: "20px" }}>
        <div className="review-grid" style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "10px"}}>
          {reviews.length > 0 ? (
            reviews.map((review, index) => (
              <div key={review.reviewId ?? `fallback-key-${index}`} 
                  className="review-item" 
                  style={{
                    position: "relative", 
                    textAlign: "center", 
                    borderRadius: "10px", 
                    cursor: "pointer", 
                    marginTop: "20px", 
                    border: "1px solid #C6C7DA" // 기존 리뷰 항목 테두리
                  }} 
                  onClick={() => openModal(review)}
              >
                  <div style={{
                    border: "2px solid #405189",
                    borderRadius: "10px",
                    padding: "10px",
                    backgroundColor: "#F9F9F9",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                    {/* 날짜 표시 */}
                    <span style={{
                      fontSize: "12px",
                      color: "gray",
                      marginBottom: "10px",
                    }}>
                      {new Date(review.insertTs).toLocaleDateString("ko-KR", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </span>

                    {/* 이미지 표시 */}
                    {review.images && review.images.length > 0 ? (
                      <img 
                        src={review.images[0]} 
                        alt="리뷰 이미지" 
                        style={{
                          width: "100%",
                          height: "300px",
                          objectFit: "cover", // 사진 크기 균일하게 유지
                          borderRadius: "5px", // 사진 둥근 테두리 추가
                          cursor: "pointer",
                        }} 
                      />
                    ) : (
                      <div style={{
                        width: "100%",
                        height: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "10px",
                      }}>
                        <p style={{ color: "gray" }}>이미지 없음</p>
                      </div>
                    )}
                  </div>
              </div>
            ))
          ) : (
            <p style={{ gridColumn: "span 4", textAlign: "center" }}>표시할 리뷰가 없습니다.</p>
          )}
        </div>

        <div className="pagination" style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
          {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              style={{
                margin: "0 5px",
                padding: "5px 10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                backgroundColor: pageNumber === page ? "#405189" : "white",
                color: pageNumber === page ? "white" : "black",
                cursor: "pointer",
              }}
            >
              {pageNumber + 1}
            </button>
          ))}
        </div>
      </div>

      {modalReview && (
        <div className="modal" style={modalStyle} onClick={closeModal}>
          <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
            {/* 리뷰 링크 (상단 고정) */}
            <div style={linkContainerStyle}>
              <a
                href={modalReview.link}
                target="_blank"
                rel="noopener noreferrer"
                style={linkStyle}
              >
                리뷰 링크 바로가기 🔗
              </a>
            </div>

            {/* 날짜 (리뷰 링크와 별도 영역, 우측 정렬) */}
            <div style={dateContainerStyle}>
              <span style={dateStyle}>
                {new Date(modalReview.insertTs).toLocaleString("ko-KR", {
                  dateStyle: "medium",
                  timeStyle: "short",
                })}
              </span>
            </div>

            {/* 이미지 리스트 (가운데 정렬) */}
            <div style={imageContainerStyle}>
              {modalReview.images && modalReview.images.length > 0 ? (
                modalReview.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`리뷰 이미지 ${index + 1}`}
                    style={imageStyle}
                  />
                ))
              ) : (
                <p style={{ color: "gray", fontSize: "14px" }}>이미지가 없습니다.</p>
              )}
            </div>

            {/* 닫기 버튼 (하단 고정) */}
            <button onClick={closeModal} style={buttonStyle}>닫기</button>
          </div>
        </div>
      )}
    </Body>
  );
};


const modalStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalContentStyle: React.CSSProperties = {
  width: "90vw",
  maxWidth: "900px",
  height: "90vh",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "12px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between", // 상단(링크+날짜) - 중앙(이미지) - 하단(버튼) 분리
};

const linkContainerStyle: React.CSSProperties = {
  width: "100%",
  textAlign: "center",
  marginTop: "20px"
};

const dateContainerStyle: React.CSSProperties = {
  width: "100%",
  textAlign: "right",
  marginBottom: "20px"
};

const linkStyle: React.CSSProperties = {
  fontSize: "25px",
  fontWeight: "bold",
  color: "#405189",
  textDecoration: "none",
  display: "inline-block",
};

const dateStyle: React.CSSProperties = {
  fontSize: "14px",
  color: "gray",
  display: "inline-block",
};

const imageContainerStyle: React.CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
  maxHeight: "90vh", // 기존보다 높이 증가
  width: "100%", // 너비를 100%로 확장
  overflowY: "auto",
};

const imageStyle: React.CSSProperties = {
  maxWidth: "95%", // 기존보다 크기 증가
  maxHeight: "90vh", // 높이 증가
  objectFit: "contain"
};


const buttonStyle: React.CSSProperties = {
  width: "120px",
  backgroundColor: "#405189",
  color: "white",
  padding: "10px",
  borderRadius: "7px",
  textAlign: "center",
  cursor: "pointer",
  marginTop: "10px",
  alignSelf: "center", // 중앙 정렬
};



export default Reviews;
