import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import Body from "../../components/Body.tsx";
import SearchBar from "../../components/SearchBar.tsx";
import "react-datepicker/dist/react-datepicker.css";

const FAQ: React.FC = () => {
  const title = "FAQ"; // 페이지 제목
  const breadcrumb = ["홈", "FAQ"]; // 페이지 경로
  const [faqList, setFaqList] = useState<any[]>([]); // FAQ 데이터 상태
  const navigate = useNavigate(); // React Router를 사용한 페이지 이동


  // 검색 이벤트 핸들러
  const handleSearch = (startDate: string, endDate: string, keyword: string) => {
    console.log("검색 요청:", { startDate, endDate, keyword });
    // 키워드 필터링 로직 추가 가능
    if (keyword) {
      const filteredFaqs = faqList.filter((faq) =>
        faq.question.includes(keyword) || faq.answer.includes(keyword)
      );
      setFaqList(filteredFaqs);
    }
  };

  // 페이지 로드 시 FAQ 데이터 가져오기
  useEffect(() => {
    alert("준비 중입니다.");
    navigate(-1);
  }, []);

  return (
    <Body title={title} breadcrumb={breadcrumb}>
      <SearchBar onSearch={handleSearch} />
    </Body>
  );
};

export default FAQ;
