export const checkTokenExpiration = (): boolean => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return true; // 토큰이 없으면 만료된 것으로 간주
  
    try {
      const tokenPayload = JSON.parse(atob(accessToken.split(".")[1])); // JWT 디코딩
      const expirationTime = tokenPayload.exp * 1000; // `exp`는 초 단위, JS는 밀리초 단위
  
      return Date.now() >= expirationTime; // 현재 시간이 만료 시간을 초과하면 true (만료됨)
    } catch (error) {
      console.error("토큰 파싱 중 오류 발생:", error);
      return true; // 오류 발생 시 만료된 것으로 간주
    }
  };
  