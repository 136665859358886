import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthenticate } from "../hooks/useAuthenticate.ts";

export const PrivateRoute = (): React.ReactElement => {
  const isAuthenticated = useAuthenticate();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!isAuthenticated && !showAlert) {
      setShowAlert(true);
      alert("세션이 만료되었습니다. 다시 로그인 해주세요.");
    }
  }, [isAuthenticated, showAlert]);

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};
