import axios from 'axios';

export const API_BASE_URL = process.env.REACT_APP_API_ROOT as string;

const get = async (apiUrl: string, accessToken: string, refreshToken: string): Promise<any> => {
    const response = await axios.get(apiUrl, {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
            "Refresh-token": refreshToken,
        }
    });
    return response.data;
};

// [클라이언트] 사용 후기 전체 데이터 조회 (GET)
export const getReviewListData = async (
    accessToken: string, 
    refreshToken: string,
    date: string,
    pageable: { page: number, size: number, sort: string }
): Promise<any> => {
    const endPoint = `/api/v2/bora/review/list?date=${date}&page=${pageable.page}&size=${pageable.size}&sort=${pageable.sort}`
    const apiUrl = API_BASE_URL + endPoint;
    return await get(apiUrl, accessToken, refreshToken);
};

// [클라이언트] 사용 후기 상세 데이터 조회 (GET)
export const getReviewDetailData = async (
    accessToken: string, 
    refreshToken: string,
    reviewId: string
): Promise<any> => {
    const endPoint = `/api/v2/bora/review/list/${reviewId}`
    const apiUrl = API_BASE_URL + endPoint;
    return await get(apiUrl, accessToken, refreshToken);
};

// [클라이언트] 방명록 사진 데이터 조회 (GET)
export const getGuestBookData = async (
    accessToken: string, 
    refreshToken: string,
    date: string,
    pageable: { page: number, size: number, sort: string }
): Promise<any> => {
    const endPoint = `/api/v2/bora/guestbook/data?date=${date}&page=${pageable.page}&size=${pageable.size}&sort=${pageable.sort}`;
    const apiUrl = API_BASE_URL + endPoint;
    return await get(apiUrl, accessToken, refreshToken);
};

// [클라이언트] 모니터링 사진 데이터 조회 (GET) V1
// export const getMonitoringPhotoData = async (
//     accessToken: string, 
//     refreshToken: string,
//     pageable: { page: number, size: number, sort: string }
// ): Promise<any> => {
//     const endPoint = `/api/v2/bora/monitoring/photo/data/v1?page=${pageable.page}&size=${pageable.size}&sort=${pageable.sort}`;
//     const apiUrl = API_BASE_URL + endPoint;
//     return await get(apiUrl, accessToken, refreshToken);
// };


// [클라이언트] 모니터링 사진 데이터 조회 (GET) V2
export const getMonitoringPhotoData = async (
    accessToken: string, 
    refreshToken: string
): Promise<any> => {
    const endPoint = `/api/v2/bora/monitoring/photo/data/v2`;
    const apiUrl = API_BASE_URL + endPoint;
    return await get(apiUrl, accessToken, refreshToken);
};

// [클라이언트] 특정 연월의 사진 데이터 조회 (GET)
export const getPhotoData = async (
    accessToken: string, 
    refreshToken: string, 
    date: string, // yyyy-MM 형식으로 전달
    pageable: { page: number, size: number, sort: string }
): Promise<any> => {
    const endPoint = `/api/v2/bora/photo/data?date=${date}&page=${pageable.page}&size=${pageable.size}&sort=${pageable.sort}`;
    const apiUrl = API_BASE_URL + endPoint;
    return await get(apiUrl, accessToken, refreshToken);
};

// [클라이언트] 사용자 전망대 로그 데이터 집계 조회 (GET)
export const getAggregateData = async (
    accessToken: string, 
    refreshToken: string, 
    startAt: string,  // yyyy-MM-dd 형식으로 전달
    endAt: string     // yyyy-MM-dd 형식으로 전달
): Promise<any> => {
    const endPoint = `/api/v2/bora/data/aggregate?startAt=${startAt}&endAt=${endAt}`;
    const apiUrl = API_BASE_URL + endPoint;
    return await get(apiUrl, accessToken, refreshToken);
};

// [클라이언트] 사용자 전망대 로그 데이터 집계 조회 (GET)
export const getAggregateDataByPc = async (
    accessToken: string, 
    refreshToken: string, 
    startAt: string,  // yyyy-MM-dd 형식으로 전달
    endAt: string     // yyyy-MM-dd 형식으로 전달
): Promise<any> => {
    const endPoint = `/api/v2/bora/data/aggregate-by-pc?startAt=${startAt}&endAt=${endAt}`;
    const apiUrl = API_BASE_URL + endPoint;
    return await get(apiUrl, accessToken, refreshToken);
};
