import React, { useEffect, useState } from "react";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import Body from "../../components/Body.tsx";
import { getMonitoringPhotoData } from "../../apis/client/clientApi.ts";

interface MonitoringPhoto {
  id: number;
  insertTs: string;
  path: string;
  pcLocation: string;
}

const Monitoring: React.FC = () => {
  const title = "모니터링";
  const breadcrumb = ["홈", "모니터링"];

  // 상태 변수
  const [monitoringData, setMonitoringData] = useState<MonitoringPhoto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalPhoto, setModalPhoto] = useState<string | null>(null);

  // API 호출 함수
  const fetchMonitoringData = async () => {
    setIsLoading(true);
    setMonitoringData([]); // 기존 데이터 초기화
  
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
  
    if (!accessToken || !refreshToken) {
      alert("인증 정보가 없습니다. 다시 로그인해 주세요.");
      setIsLoading(false);
      return;
    }
  
    try {
      const response = await getMonitoringPhotoData(accessToken, refreshToken);
  
      const newData = response.data?.content ?? []; // content가 undefined일 경우 빈 배열 반환
  
      if (Array.isArray(newData)) {
        setMonitoringData([...newData]); // 배열을 복사하여 React가 상태 변경을 감지하도록 설정
      } else {
        console.warn("예상한 데이터 구조가 아닙니다.");
        setMonitoringData([]); // 올바른 응답이 아닐 경우 빈 배열로 설정
      }
    } catch (error) {
      console.error("모니터링 사진 데이터 API 호출 중 오류 발생:", error);
      alert("모니터링 사진 데이터를 가져오는 중 오류가 발생했습니다.");
    } finally {
      setIsLoading(false);
    }
  };

  // 새로고침 버튼
  const handleRefresh = () => {
    fetchMonitoringData();
  };

  // 모달 기능
  const openModal = (photoUrl: string) => setModalPhoto(photoUrl);
  const closeModal = () => setModalPhoto(null);

  // API 호출 실행
  useEffect(() => {
    fetchMonitoringData();
  }, []);

  return (
    <Body title={title} breadcrumb={breadcrumb}>
      {/* 새로고침 버튼 */}
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
        <button 
          onClick={handleRefresh} 
          style={{ 
            backgroundColor: "#405189", 
            color: "white", 
            padding: "10px 15px", 
            borderRadius: "7px", 
            border: "none", 
            cursor: "pointer"
          }}
        >
          🔄 새로고침
        </button>
      </div>

      {/* 데이터 박스 */}
      <div className="box-container" style={{ backgroundColor: "white", borderRadius: "10px", padding: "20px" }}>
        {isLoading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        ) : monitoringData && monitoringData.length > 0 ? (
          <div className="photo-grid" style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "20px" }}>
            {monitoringData.map((photo, index) => (
              <div
                key={photo.id || `photo-${index}`}
                className="photo-item"
                style={{
                  position: "relative",
                  textAlign: "start",
                  borderRadius: "10px",
                  padding: "10px",
                  cursor: "pointer",
                  border: "2px solid #405189",
                }}
                onClick={() => openModal(photo.path)}
              >
                {/* 날짜 및 위치 표시 */}
                <div style={{ fontSize: "14px", fontWeight: "bold", color: "#333", marginBottom: "5px" }}>
                  PC: {photo.pcLocation}
                </div>
                <div style={{ fontSize: "12px", color: "gray", marginBottom: "5px" }}>
                  {new Date(photo.insertTs).toLocaleDateString("ko-KR", {year: "numeric", month: "long", day: "numeric"})}{" "}

                  {new Date(photo.insertTs).toLocaleTimeString("ko-KR", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true, // 오전/오후 표시
                  })}
                </div>
                
                {/* 사진 */}
                <img 
                  src={photo.path} 
                  alt="모니터링 사진" 
                  style={{ width: "100%", height: "auto", borderRadius: "10px" }} 
                />
              </div>
            ))}
          </div>
        ) : (
          <h3 style={{ gridColumn: "span 3", textAlign: "center" }}>표시할 사진이 없습니다.</h3>
        )}
      </div>
      {modalPhoto && (
        <div className="modal" style={modalStyle} onClick={closeModal}>
          <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
            <img src={modalPhoto} alt="" style={{ width: "100%", height: "100%" }} />
            <button onClick={closeModal} style={{ backgroundColor : "#405189", color : "white", padding: "10px", borderRadius: "7px", marginTop: "10px"}}>닫기</button>
          </div>
        </div>
      )}
    </Body>
  );
};

// 스타일 객체 타입 정의
const modalStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalContentStyle: React.CSSProperties = {
  width: "90%",
  maxWidth: "1300px",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "10px",
  textAlign : "center"
};

export default Monitoring;
