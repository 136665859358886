import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import Body from "../../components/Body.tsx";
import SearchBar from "../../components/SearchBar.tsx";
import { getAggregateData, getAggregateDataByPc, getPhotoData, getReviewListData, getReviewDetailData, getGuestBookData } from "../../apis/client/clientApi.ts";
import EmptyIcon from "../../assets/images/Empty_icons.png";

// AggregateSummary(보라 로그 집계) Props 인터페이스 정의
interface AggregateSummary {
  userCount: number;
  mostUsedPc?: {
    name: string;
    percentage: number;
    totalCount: number;
  };
}

// Review(사용 후기) Props 인터페이스 정의
interface Review {
  reviewId: number;
  images?: string[];  // `images`가 존재하지 않을 수도 있으므로 `?` 추가
}


// 현재 날짜를 yyyy-MM 형식으로 반환하는 함수
const getCurrentDate = (): string => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  return `${year}-${month}`;
};


const Dashboard: React.FC = () => {
  const title = "홈화면";
  const breadcrumb = ["홈화면"];
  const [aggregateData, setAggregateData] = useState<any>(null);
  const [aggregateDataByPc, setAggregateDataByPc] = useState<any>(null);
  const [summary, setSummary] = useState<AggregateSummary | null>(null);
  const [photoData, setPhotoData] = useState<any[]>([]);
  const [photoDate, setPhotoDate] = useState<string>(getCurrentDate());
  const [isLoading, setIsLoading] = useState(false);  // 로딩 상태 추가
  const [isDataReady, setIsDataReady] = useState(false);  // 데이터 준비 상태 추가
  const [guestBookData, setGuestBookData] = useState<any[]>([]);
  const [reviewData, setReviewData] = useState<any[]>([]);
  const [photoCount, setPhotoCount] = useState<number>(0);  // 사진 개수
  const [totalPhotoCount, setTotalPhotoCount] = useState<number>(0);  // 전체 사진 개수 상태 추가
  const [guestBookCount, setGuestBookCount] = useState<number>(0);  // 방명록 개수
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [isPhotoLoading, setIsPhotoLoading] = useState(false);
  const [isGuestBookLoading, setIsGuestBookLoading] = useState(false);
  const [isReviewLoading, setIsReviewLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {

    // 화면 초기 데이터 로딩을 위한 함수
    const fetchInitialData = async () => {
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      const yesterdayString = yesterday.toISOString().split("T")[0];

      const oneMonthAgo = new Date(yesterday);
      oneMonthAgo.setMonth(yesterday.getMonth() - 1);
      const oneMonthAgoString = oneMonthAgo.toISOString().split("T")[0];

      setStartDate(oneMonthAgoString);
      setEndDate(yesterdayString);

      await handleSearchBarChange(oneMonthAgoString, yesterdayString, ""); // 초기 데이터 조회 실행
    };

    fetchInitialData();
  }, []);

  // SearchBar 날짜 변경에 따른 데이터 조회 함수
  const handleSearchBarChange = (startDate: string, endDate: string, selectedOption: string) => {
    setIsLoading(true);
    setIsDataReady(false);

    fetchAllData(startDate, endDate, selectedOption);
    fetchPhotoData(startDate, endDate);
    fetchGuestBookData(startDate, endDate);
    fetchReviewData(startDate, endDate);
  };

  // 공통 네비게이션 함수: 여러 페이지로 이동 가능
  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const fetchReviewData = async (startDate: string, endDate: string) => {
    setIsReviewLoading(true);
    const timer = setTimeout(() => setIsReviewLoading(false), 5000);

    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    // startDate ~ endDate 사이의 모든 yyyy-MM 리스트 만들기
    const start = new Date(startDate);
    const end = new Date(endDate);
    let current = new Date(start);
    const months: string[] = [];

    while (current <= end) {
        const year = current.getFullYear();
        const month = String(current.getMonth() + 1).padStart(2, "0");
        months.push(`${year}-${month}`);
        current.setMonth(current.getMonth() + 1);
    }

    try {
        // 각 연월별로 리뷰 데이터를 가져오기 (병렬 처리)
        const reviewResponses = await Promise.all(
            months.map(async (month) => {
                const response = await getReviewListData(accessToken!, refreshToken!, month, { page: 0, size: 4, sort: "id,desc" });

                if (response.result === true && response.data?.content) {
                    return response.data.content;
                } else {
                    return []; // 데이터가 없으면 빈 배열 반환
                }
            })
        );

        // 여러 연월의 데이터를 하나로 합치기
        const allReviews = reviewResponses.flat(); // 중첩된 배열을 평탄화

        if (allReviews.length === 0) {
            console.warn("조회된 사용 후기 데이터가 없습니다.");
            setReviewData([]);
        } else {
            // 각 리뷰의 상세 데이터 가져오기
            const reviewThumbnails = await Promise.all(
                allReviews.map(async (review: any) => {
                    try {
                        const detailResponse = await getReviewDetailData(accessToken!, refreshToken!, review.id.toString());

                        return {
                            reviewId: review.id,
                            imageUrl: (Array.isArray(detailResponse.data?.images) && detailResponse.data.images.length > 0)
                                ? detailResponse.data.images[0]  // 첫 번째 이미지를 사용
                                : EmptyIcon
                        };
                    } catch (error) {
                        console.error(`리뷰 상세 조회 실패 (ID: ${review.id})`, error);
                        return { reviewId: review.id, imageUrl: EmptyIcon };
                    }
                })
            );
            setReviewData(reviewThumbnails);
        }
    } catch (error) {
        console.error("리뷰 데이터 API 호출 중 오류 발생:", error);
        alert("리뷰 데이터를 가져오는 데 실패했습니다.");
        setReviewData([]);
    } finally {
        clearTimeout(timer);
        setIsReviewLoading(false);  // 로딩 상태 해제
    }
  };

  // 방명록 데이터 호출 함수
  const fetchGuestBookData = async (startDate: string, endDate: string) => {
    setIsGuestBookLoading(true);
    const timer = setTimeout(() => setIsGuestBookLoading(false), 5000);
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    // startDate ~ endDate 사이의 모든 yyyy-MM 리스트 만들기
    const start = new Date(startDate);
    const end = new Date(endDate);
    let current = new Date(start);
    const months: string[] = [];

    while (current <= end) {
      const year = current.getFullYear();
      const month = String(current.getMonth() + 1).padStart(2, "0");
      months.push(`${year}-${month}`);
      current.setMonth(current.getMonth() + 1);
  }
  
  try {
    let allGuestBookPhotos: any[] = [];
    let totalCount = 0;

    // 모든 yyyy-MM에 대해 API 호출
    for (const date of months) {
        const response = await getGuestBookData(accessToken!, refreshToken!, date, { page: 0, size: 4, sort: "boraPhotoId,desc" });

        if (response.result === true && response.data?.page?.content) {
          allGuestBookPhotos = [...allGuestBookPhotos, ...response.data.page.content]; // 모든 방명록 사진 추가
            totalCount += response.data.page.totalElements ?? response.data.page.content.length;
        }
    }

    setGuestBookData(allGuestBookPhotos.slice(0, 4)); // 객체 자체를 저장
    setGuestBookCount(totalCount); // 전체 방명록 사진 개수 반영

    } catch (error) {
        console.error("방명록 데이터 API 호출 중 오류 발생:", error);
        alert("방명록 데이터를 가져오는 데 실패했습니다.");
    } finally {
      clearTimeout(timer);
      setIsGuestBookLoading(false);
    }
  };

  // 보라 사진 데이터 호출 함수
  const fetchPhotoData = async (startDate: string, endDate: string) => {
    setIsPhotoLoading(true);
    const timer = setTimeout(() => setIsPhotoLoading(false), 5000);
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    // startDate ~ endDate 사이의 모든 yyyy-MM 리스트 만들기
    const start = new Date(startDate);
    const end = new Date(endDate);
    let current = new Date(start);
    const months: string[] = [];

    while (current <= end) {
        const year = current.getFullYear();
        const month = String(current.getMonth() + 1).padStart(2, "0");
        months.push(`${year}-${month}`);
        current.setMonth(current.getMonth() + 1);
    }

    try {
        let allPhotos: any[] = [];
        let totalCount = 0;

        // 모든 yyyy-MM에 대해 API 호출
        for (const date of months) {
            const response = await getPhotoData(accessToken!, refreshToken!, date, { page: 0, size: 4, sort: "boraPhotoId,desc" });

            if (response.result === true && response.data?.page?.content) {
                allPhotos = [...allPhotos, ...response.data.page.content]; // 모든 사진 추가
                totalCount += response.data.page.totalElements ?? response.data.page.content.length;
            }
        }

        setPhotoData(allPhotos.slice(0, 4)); // 객체 자체를 저장
        setTotalPhotoCount(totalCount); // 전체 사진 개수 반영

    } catch (error) {
        console.error("사진 데이터 API 호출 중 오류 발생:", error);
        alert("사진 데이터를 가져오는 데 실패했습니다.");
    } finally {
      clearTimeout(timer);
      setIsPhotoLoading(false);
    }
  };

  // 보라 s3 버킷 주소 (download_count 제한으로 인한 s3 주소 하드코딩용)
  const S3_BASE_URL = "https://borabucket.s3.ap-northeast-2.amazonaws.com/public/";

  // S3 주소 반영 함수
  const convertToPhotoS3Url = (photo: any) => {
    if (!photo.downloadUrl || !photo.fileName) return ""; 

    // 기존 URL에서 파일명(uuid 포함)만 추출
    const uuidFilename = photo.downloadUrl.split("/").pop(); // 마지막 '/' 이후의 문자열 가져오기
    const pcsystemid = photo.fileName.split("-")[0]; // 파일명에서 'pcsystemid' 추출

    return `${S3_BASE_URL}${pcsystemid}/${uuidFilename}`;
  };

  // 로그 집계 관련 API 호출
  const fetchAllData = async (startDate: string, endDate: string, pcSystemId: string) => {
    setIsLoading(true);
    setIsDataReady(false);

    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    try {
        // 모든 API 동시 호출
        const [aggregateResponse, pcResponse] = await Promise.all([
            getAggregateData(accessToken!, refreshToken!, startDate, endDate),
            getAggregateDataByPc(accessToken!, refreshToken!, startDate, endDate),
            
        ]);

        if (aggregateResponse.result && pcResponse.result) {
            // 상태 업데이트
            setAggregateData(aggregateResponse.data);
            setAggregateDataByPc(pcResponse.data);
            generateSummary(aggregateResponse.data, pcResponse.data);
        }

    } catch (error) {
        console.error("로그 집계 데이터 API 호출 중 오류 발생:", error);
        alert("로그 데이터를 가져오는 데 실패했습니다.");
    } finally {
        setIsLoading(false);
        setIsDataReady(true);
    }
  };

  // 보라 로그 집계 데이터 호출 함수 
  const generateSummary = (data: any, pcData: any) => {
    const userCount = (Object.values(data.day_of_week || {}) as number[]).reduce((sum, count) => sum + count, 0);
    
    let mostUsedPc: AggregateSummary['mostUsedPc'] = undefined;
  
    if (pcData) {
      const pcUsageSummary = Object.entries(pcData).map(([pcName, pcData]: [string, any]) => {
        const values = Object.values(pcData.day_of_week || {}) as number[];
        const totalUsage = values.reduce((sum, count) => sum + count, 0);
  
        return {
          name: pcName,
          totalCount: totalUsage,
          percentage: Number(((totalUsage / userCount) * 100).toFixed(1)),
        };
      });
  
      if (pcUsageSummary.length > 0) {
        mostUsedPc = pcUsageSummary.reduce((max, current) => (current.totalCount > max.totalCount ? current : max), pcUsageSummary[0]);
      }
    }
    setSummary({ userCount, mostUsedPc });
  };

  return (
    <>
      <Body title={title} breadcrumb={breadcrumb}>
        <SearchBar onSearch={handleSearchBarChange} />
        <div className="box-container" style={{ backgroundColor: "white", borderRadius: "10px", padding: "20px" }}>
          <div className="data-container">
            <div className="data-text">
              {isLoading ? (
                <h3>⏳ 데이터를 불러오는 중입니다... 잠시만 기다려 주세요.</h3>
              ) : isDataReady ? (
                summary ? (
                  <div>
                    <h3>조회 기간 동안 BORA를 <strong>{summary.userCount.toLocaleString()}</strong>명 이상 사용했어요.</h3>
                    {summary.mostUsedPc ? (
                      <h3><strong>{summary.mostUsedPc.name}</strong> BORA가 약 <strong>{summary.mostUsedPc.percentage.toFixed(1).toLocaleString()}%</strong>로 가장 많이 사용되었어요.</h3>
                    ) : (
                      <h3>PC별 데이터가 없습니다.</h3>
                    )}
                    <h3>BORA로 촬영된 사진은 <strong>{totalPhotoCount.toLocaleString()}</strong>장 입니다.</h3>
                    <h3>BORA에 등록된 방명록은 <strong>{guestBookCount.toLocaleString()}</strong>개 입니다.</h3>
                  </div>
                ) : (
                  <h3>해당 날짜 기준 데이터가 존재하지 않습니다</h3>
                )
              ) : (
                <h3>해당 조회 기간 내 데이터가 준비되지 않았습니다.</h3>
              )}
            </div>
            <div className="btn-container">
              <p>상세 데이터는 <strong>데이터 조회 탭</strong>에서 확인 가능합니다.</p>
              <button className="btn-navigate" onClick={() => handleNavigate("/Dataview")}>바로 가기</button>
            </div>
          </div>
          <hr style={{ border: "1px solid black", width: "100%", display: "block" }} />

          <div className="photo-container" style={{ marginTop: "20px" }}>
            <h3>사진</h3>
            <div className="btn-container">
              <p>상세 데이터는 <strong>사진 탭</strong>에서 확인 가능합니다.</p>
              <button className="btn-navigate" onClick={() => handleNavigate("/Photos")}>바로 가기</button>
            </div>

            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap"}}>
              {isPhotoLoading ? (
                <div className="spinner-container">
                  <div className="spinner"></div>
                </div>
              ) : (
                <>
                  {photoData.slice(0, 4).map((photo, index) => (
                    <img
                      key={index}
                      src={convertToPhotoS3Url(photo)}
                      alt={`Photo ${index + 1}`}
                      style={{ width: "300px", height: "250px", objectFit: "cover", borderRadius: "10px" }}
                    />
                  ))}
                  {/* 데이터가 4개 미만일 경우 EmptyIcon 추가 */}
                  {Array(4 - Math.min(4, photoData.length)).fill(null).map((_, index) => (
                    <img
                      key={`empty-${index}`}
                      src={EmptyIcon}
                      alt="Empty"
                      style={{ width: "300px", height: "250px", objectFit: "contain", borderRadius: "10px" }}
                    />
                  ))}
                </>
              )}
            </div>
          </div>

          <hr style={{ border: "1px solid black", width: "100%", margin: "20px 0", display: "block" }} />

          {/* 방명록 섹션 */}
          <div className="guestbook-container" style={{ marginTop: "20px" }}>
            <h3>방명록</h3>
            <div className="btn-container">
              <p>상세 데이터는 <strong>방명록 탭</strong>에서 확인 가능합니다.</p>
              <button className="btn-navigate" onClick={() => handleNavigate("/GuestBook")}>바로 가기</button>
            </div>
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap"}}>
              {isGuestBookLoading ? (
                <div className="spinner-container">
                  <div className="spinner"></div>
                </div>
              ) : (
                <>
                  {guestBookData.slice(0, 4).map((photo, index) => (
                    <img
                      key={index}
                      src={photo.imageUrl}
                      alt={`GuestBook Photo ${index + 1}`}
                      style={{ width: "300px", height: "250px", objectFit: "cover", borderRadius: "10px" }}
                    />
                  ))}
                  {/* 데이터가 4개 미만일 경우 EmptyIcon 추가 */}
                  {Array(4 - Math.min(4, guestBookData.length)).fill(null).map((_, index) => (
                    <img
                      key={`empty-placeholder-${index}`}
                      src={EmptyIcon}
                      alt="Empty"
                      style={{ width: "300px", height: "250px", objectFit: "contain", borderRadius: "10px"}}
                    />
                  ))}
                </>
              )}
            </div>
          </div>

          <hr style={{ border: "1px solid black", width: "100%", margin: "20px 0", display: "block" }} />

          {/* 사용 후기 섹션 */}
          <div className="review-container" style={{ marginTop: "20px" }}>
            <h3>사용 후기</h3>
            <div className="btn-container">
                <p>상세 데이터는 <strong>사용 후기 탭</strong>에서 확인 가능합니다.</p>
                <button className="btn-navigate" onClick={() => handleNavigate("/Reviews")}>바로 가기</button>
            </div>
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap"}}>
              {isReviewLoading ? (
                <div className="spinner-container">
                  <div className="spinner"></div>
                </div>
              ) : (
                <>
                  {reviewData.slice(0, 4).map((review, index) => (
                    <img
                      key={`${review.reviewId}-${index}`}
                      src={review.imageUrl}
                      alt={`Review ${index + 1}`}
                      style={{ width: "300px", height: "250px", objectFit: "cover", borderRadius: "10px" }}
                    />
                  ))}
                  {/* 데이터가 4개 미만일 경우 EmptyIcon 추가 */}
                  {Array(4 - Math.min(4, reviewData.length)).fill(null).map((_, index) => (
                    <img
                      key={`empty-placeholder-${index}`}
                      src={EmptyIcon}
                      alt="Empty"
                      style={{ width: "300px", height: "250px", objectFit: "contain", borderRadius: "10px"}}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </Body>
    </>
  );
};

export default Dashboard;