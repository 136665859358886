import { useState, useEffect } from "react";
import { checkTokenExpiration } from "../utils/checkTokenExpiration.ts";

export const useAuthenticate = (): boolean => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem("accessToken"));

  useEffect(() => {
    const checkAuthStatus = () => {
      if (checkTokenExpiration()) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        setIsAuthenticated(false);
      }
    };

    // 5초마다 토큰 상태를 체크
    const interval = setInterval(checkAuthStatus, 5000);

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 정리
  }, []);

  return isAuthenticated;
};
