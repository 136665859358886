//백오피스 헤더 및 사이드바 구성
import React, { useEffect } from "react";
import Sidebar from "./SideBar.tsx";
import ContentsHeader from "./ContentsHeader.tsx";
import { useNavigate } from "react-router-dom";
import "../styles/common/common.css";
import "../styles/dashboard/dashboard.css";
import "../pages/Login.tsx";

interface BodyProps {
  title: string;
  breadcrumb: string[];
  searchBar?: React.ReactNode;
  children?: React.ReactNode;
}

const Body: React.FC<BodyProps> = ({
  title,
  breadcrumb,
  searchBar,
  children,
}) => {
  const navigate = useNavigate();

  //로그아웃지 토큰들 지우고 사이드바 값 초기화
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.setItem("depth", "0");
    localStorage.setItem("depth2", "0");
    localStorage.setItem("main", "1");
    localStorage.removeItem("role");
    localStorage.removeItem("group");

    // 세션 스토리지도 초기화
    sessionStorage.clear();

    navigate("/");
  };


  //역할값이 없을경우 로그인 화면으로 되돌리기
  useEffect(() => {
    // 기존 소스 : role을 가지고 화면 이동 판단
    // const role = localStorage.getItem("role");
    // if (role === "null") {
    //   navigate("/");
    // }
    
    // 변경 소스 : accessToken으로 화면 이동 판단
    const accessToken = localStorage.getItem("accessToken");

    // accessToken이 없으면 로그인 페이지로 이동
    if (!accessToken) {
      navigate("/");
    }

  }, [navigate]);

  return (
    <div className="wrap">
      <div className="sideWrap">
        <Sidebar />
      </div>
      <div className="container">
        <header className="header">
          <button
            className="logout-button"
            onClick={() => {
              if (window.confirm("로그아웃 하시겠습니까?")) {
                logout();
              }
            }}
          >
            로그아웃
          </button>
        </header>
        <div className="contents">
          <ContentsHeader title={title} breadcrumb={breadcrumb} />
          <div className="contents__cont">
            <div className="member">{searchBar}</div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
