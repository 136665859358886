import React, { useEffect, useState } from "react";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import Body from "../../components/Body.tsx";
import "react-datepicker/dist/react-datepicker.css";
import { getGuestBookData } from "../../apis/client/clientApi.ts";
import CustomDatePicker from "../../components/CustomDatePicker.tsx";

// GuestBook(방명록) Props 인터페이스 정의
interface GuestBook {
  id: number;
  imageUrl: string;
}

const GuestBook: React.FC = () => {
  const [guestBookData, setguestBookData] = useState<GuestBook[]>([]);
  const [currentDate, setCurrentDate] = useState(() => {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}`;
  });
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedGuestBooks, setSelectedGuestBooks] = useState<number[]>([]);
  const [modalPhoto, setModalPhoto] = useState<string | null>(null);
  
  // 사진 데이터 호출 함수
  const fetchPhotoData = async (date: string, pageNumber: number) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (!accessToken || !refreshToken) {
      alert("인증 정보가 없습니다. 다시 로그인해 주세요.");
      return;
    }

    try {
      const response = await getGuestBookData(accessToken, refreshToken, date, {
        page: pageNumber,
        size: 16,
        sort: "id,desc"
      });

      if (response.result === true && response.data?.page?.content) {
        setguestBookData(response.data.page.content);
        setTotalPages(response.data.page.totalPages);
      } else {
        setguestBookData([]);
      }
    } catch (error) {
      console.error("방명록 사진 데이터 API 호출 중 오류 발생:", error);
      alert("방명록 사진 데이터를 가져오는 중 오류가 발생했습니다.");
    }
  };

  const startPage = Math.max(0, page - 5);
  const endPage = Math.min(totalPages - 1, page + 4);
  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

  const buttonStyle: React.CSSProperties = {
    marginLeft: "10px",
    padding: "8px 12px",
    backgroundColor: "#405189",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };
  
  // 이전 달 계산 함수
  const handlePrevMonth = () => {
    const [year, month] = currentDate.split("-").map(Number);
    const newDate = new Date(year, month - 2);
    setCurrentDate(`${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, "0")}`);
  };

  // 다음 달 계산 함수
  const handleNextMonth = () => {
    const [year, month] = currentDate.split("-").map(Number);
    const newDate = new Date(year, month);
    setCurrentDate(`${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, "0")}`);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const toggleSelect = (guestBookId: number) => {
    setSelectedGuestBooks((prev) =>
      prev.includes(guestBookId) ? prev.filter((id) => id !== guestBookId) : [...prev, guestBookId]
    );
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedGuestBooks(guestBookData.map((guestBook) => guestBook.id));
    } else {
      setSelectedGuestBooks([]);
    }
  };

  // 방명록 사진 삭제 기능 (API X)
  const deleteSelectedPhotos = async () => {
    if (selectedGuestBooks.length === 0) {
      alert("삭제할 사진을 선택해주세요.");
      return;
    }

    const confirmDelete = window.confirm("선택한 사진을 삭제하시겠습니까?");
    if (!confirmDelete) return; // 사용자가 '아니오' 선택 시 종료
  
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
  
    if (!accessToken || !refreshToken) {
      alert("인증 정보가 없습니다. 다시 로그인해 주세요.");
      return;
    }
  
    try {
      const URL = process.env.REACT_APP_API_ROOT;  // 도메인 연결 주소 설정
      const queryString = selectedGuestBooks.map(id => `guestBookIds=${id}`).join("&");
  
      const response = await fetch(`${URL}/api/v2/bora/guestbook/delete?${queryString}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      let result;
      try {
        result = await response.json();
      } catch (error) {
        console.error("서버 응답이 JSON이 아닙니다.", error);
        alert("서버 오류로 인해 삭제할 수 없습니다.");
        return;
      }
  
      if (response.status === 200) {
        alert(result.message);
        setguestBookData(prevGuestBooks =>
          prevGuestBooks .filter(GuestBook => !selectedGuestBooks .includes(GuestBook.id))
        );
        setguestBookData([]);
      } else if (response.status === 403) {
        alert("삭제 권한이 없는 사진이 포함되어 있습니다.");
      } else if (response.status === 404) {
        alert("존재하지 않거나 이미 삭제된 데이터입니다.");
      } else {
        alert("삭제 중 오류가 발생했습니다.");
      }
    } catch (error) {
      console.error("사진 삭제 API 호출 중 오류 발생:", error);
      alert("서버 오류로 인해 삭제할 수 없습니다.");
    }
  };

  // 다운로드 버튼 클릭 시 알림 추가
  const handleDownloadClick = () => {
    alert("준비 중입니다.");
  };

  const openModal = (photoUrl: string) => setModalPhoto(photoUrl);
  const closeModal = () => setModalPhoto(null);

  useEffect(() => {
    fetchPhotoData(currentDate, page);
  }, [currentDate, page]);

  return (
    <Body title="방명록" breadcrumb={["홈", "방명록"]}>
      <div
        className="date-navigation"
        style={{
          display: "flex",
          justifyContent: "space-between", // 양쪽 정렬
          alignItems: "center",
          padding: "10px 20px",
        }}
      >
        {/* 전체 선택 체크박스 */}
        <div className="all-checkbox">
          <input type="checkbox" onChange={handleSelectAll} />
          <label>전체 선택</label>
        </div>

        {/* DatePicker 중앙 정렬 */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            justifyContent: "space-between",
            width: "280px",
            minWidth: "280px",
            marginLeft: "70px"
          }}
        >
          <button className="nav-button" onClick={handlePrevMonth}>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 18l-6-6 6-6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>


          <div style={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
            <CustomDatePicker currentDate={currentDate} setCurrentDate={setCurrentDate} />
          </div>

          <button className="nav-button" onClick={handleNextMonth}>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18l6-6-6-6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
        
        <div>
          <button onClick={deleteSelectedPhotos} style={buttonStyle}>삭제</button>
          <button onClick={handleDownloadClick} style={buttonStyle}>다운로드</button>
        </div>
      </div>
      <div className="box-container" style={{ backgroundColor: "white", borderRadius: "10px", padding: "20px" }}>
        <div className="photo-grid" style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "10px"}}>
          {guestBookData.length > 0 ? (
            guestBookData.map((guestBook) => (
              <div
                key={guestBook.id}
                className="photo-item"
                style={{
                  position: "relative",
                  textAlign: "center",
                  border: selectedGuestBooks.includes(guestBook.id) ? "4px solid #405189" : "none",
                  borderRadius : "10px"
                }}
                onDoubleClick={() => openModal(guestBook.imageUrl)}
              >
                <div
                    style={{
                      border: selectedGuestBooks.includes(guestBook.id) ? "none" : "2px solid #405189",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                <input
                  type="checkbox"
                  style={{ position: "absolute", top: "10px", left: "10px", zIndex: 2 }}
                  checked={selectedGuestBooks.includes(guestBook.id)}
                  onChange={() => toggleSelect(guestBook.id)}
                />
                <img
                  src={guestBook.imageUrl}
                  alt=""
                  style={{ width: "100%", height: "auto", borderRadius: "10px", cursor: "pointer" }}
                  onClick={() => toggleSelect(guestBook.id)}
                />
                </div>
              </div>
            ))
          ) : (
            <p style={{ gridColumn: "span 4", textAlign: "center" }}>표시할 방명록 사진이 없습니다.</p>
          )}
        </div>
        <div className="pagination" style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              style={{
                margin: "0 5px",
                padding: "5px 10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                backgroundColor: pageNumber === page ? "#405189" : "white",
                color: pageNumber === page ? "white" : "black",
                cursor: "pointer",
              }}
            >
              {pageNumber + 1}
            </button>
          ))}
        </div>
      </div>

      {modalPhoto && (
        <div className="modal" style={modalStyle} onClick={closeModal}>
          <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
            <img src={modalPhoto} alt="" style={{ width: "100%", height: "100%" }} />
            <button onClick={closeModal} style={{ backgroundColor : "#405189", color : "white", padding: "10px", borderRadius: "7px", marginTop: "10px"}}>닫기</button>
          </div>
        </div>
      )}
    </Body>
  );
};

// 스타일 객체 타입 정의
const modalStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalContentStyle: React.CSSProperties = {
  width: "80%",
  maxWidth: "600px",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "10px",
  textAlign : "center"
};


export default GuestBook;
